import $ from 'jquery';
import ioc2iso from '../resources/ioc_to_iso2.json';

var alpineskiingProcessor = {
  processData(data, event, currentUrl, ranking_data){
    var $data = $(data);

    $data = this.noResultAvailableInfo($data);
    $data = this.sidebar($data);
    // $data = this.tabsForResults($data);
    $data = this.targetBlank($data);
    $data = this.tabs($data, ranking_data);
    $data = this.podium($data);
    $data = this.cleanTable($data);
    $data = this.responsiveTable($data);
    $data = this.flagIcon($data);
    $data = this.tableResult($data);

    // console.log(wcRanking, 'processData - wcRanking');
    // console.log(disciplineRanking, 'processData - disciplineRanking');

    // wrap all this content before return it
    var $dataWrapper = $('<div></div>');
    $dataWrapper.append($data);
    return $dataWrapper;
  },

  noResultAvailableInfo($data){

    const $info = $data.find('#informations');
    const $fieldset = $info.find('fieldset').first();
    const infoText = $fieldset.children('h4').text();

    if(infoText === 'No Results Available') {
      $info.addClass('text-center');

      if($('.live-timing').length > 0){
        $fieldset.html('<div class="no-res-available"><h2>' + infoText + '</h2><p>Please click on Live Timing for more informations</p></div>');
      } else {
        $fieldset.html('<h2 class="no-res-available">' + infoText + '</h2>');
      }

      //flag
      const $fieldsetWinner = $info.find('fieldset').eq(1);
      var $country = $fieldsetWinner.find('p').eq(1);
      var countryIso = $country.text().replace('(','').replace(')','');
      $country.hide();
      $fieldsetWinner.addClass('defending-winner');
      $fieldsetWinner.append('<span class="flag-icon flag-icon-'+countryIso+'"> </span>')
    }

    return $data;
  },

  targetBlank($data){
    var links = $data.find('a.dl-link')
    links.attr('target','_blank');
    return $data;
  },

  sidebar($data){
    var $row = $('<div class="row"></div>');
    var $main = $('<div class="col-lg-12 event-content"></div>');
    var $sidebar = $('<div class="col-lg-12 event-additionnal-info"></div>');

    var $download_links = $data.find('.dl-link');
    var $moreInfo = $data.find('#moreInfoCollapse');

    //remove more info button
    $moreInfo.parent().find('.btn-primary').remove();

    // create final layout
    $main.append($data);
    $sidebar.append($download_links);
    $sidebar.append($moreInfo.children());

    $row.append($main);
    $row.append($sidebar);

    return $row;
  },

  tabsForResults($data){
    var $results = $data.find('.results');

    // move h3 as the first h4
    var $firstTabTiltes = $results.parent().find('h3');
    $firstTabTiltes.find('a').remove();
    $results.prepend('<h4>'+$firstTabTiltes.text()+'</h4>');
    $firstTabTiltes.remove();

    var $titles = $results.find('h4');
    var $tables = $results.find('table');

    //build boostrap tabs

    var $tabNavs = $('<ul class="nav nav-pills nav-fill" id="nav-tab" role="tablist"></ul>');
    $titles.each((i, el)=>{
      var active = '';
      if(i === 0){
        active = 'active';
      }
      var $el = $(el);
      var $link = $(' <li class="nav-item"><a class="nav-link '+active+'" id="nav-home-tab" data-toggle="tab" href="#nav-'+i+'" role="tab" aria-controls="nav-home" aria-selected="true">'+$el.html()+'</a></li>');
      $tabNavs.append($link);
    });

    var $tabsContents= $('<div class="tab-content" id="nav-tabContent"></div>');
    $tables.each((i, el)=>{
      var active = '';
      if(i === 0){
        active = 'show active';
      }
      var $el = $(el);
      var $content = $('<div class="tab-pane fade '+active+'" id="nav-'+i+'" role="tabpanel" aria-labelledby="nav-home-tab"></div>');
      $content.html($el);
      $tabsContents.append($content);
    });
    $tabNavs.wrap('<nav></nav>')

    $results.html($tabNavs);
    $results.append($tabsContents);

    return $data;
  },
  tabs($data, ranking_data){
    var $results = $data.find('.results');

    // move h3 as the first h4
    var $firstTabTiltes = $results.parent().find('h3');
    $firstTabTiltes.find('a').remove();
    // $results.prepend('<h4>'+$firstTabTiltes.text()+'</h4>');
    $firstTabTiltes.remove();

    // var $titles = ['Official results', 'Organisation'];
    var $titles = ['Race results'];

    if(ranking_data.discipline.ranking){
      $titles.push(ranking_data.discipline.tab_title)
    }
    if(ranking_data.general.ranking){
      $titles.push(ranking_data.general.tab_title);
    }
    if(ranking_data.rss.ranking){
      $titles.push(ranking_data.rss.tab_title);
    }


    var $sidebar = $data.find('.event-additionnal-info');
    var $download_links = $sidebar.find('a.dl-link');
    var $downloads_wrapper =  $('<div class="downloads"></div>');
    $downloads_wrapper.append($download_links);

    // remove unnecessary element
    $results.find('ul').remove();
    $results.find('h5').remove();
    $sidebar.children('div').children('div').first().remove();

    $results.append($downloads_wrapper);

    // var $contents = [$results.html() , $sidebar];
    var $contents = [$results.html()];
    if(ranking_data.discipline.ranking){
      $contents.push(ranking_data.discipline.ranking);
    }
    if(ranking_data.general.ranking){
      $contents.push(ranking_data.general.ranking);
    }
    if(ranking_data.rss.ranking){
      $contents.push(ranking_data.rss.ranking);
    }

    //build boostrap tabs
    var $tabNavs = $('<ul class="nav nav-pills nav-fill" id="nav-tab" role="tablist"></ul>');
    var i = 0;
    $titles.forEach((el)=>{
      var active = '';
      if(i === 0){
        active = 'active';
      }
      var $link = $(' <li class="nav-item"><a class="nav-link '+active+'" id="nav-home-tab" data-toggle="tab" href="#nav-'+i+'" role="tab" aria-controls="nav-home" aria-selected="true">'+el+'</a></li>');
      $tabNavs.append($link);
      i++;
    });

    var $tabsContents= $('<div class="tab-content" id="nav-tabContent"></div>');
    i = 0;
    $contents.forEach((el)=>{
      var active = '';
      if(i === 0){
        active = 'show active';
      }
      var $el = $(el);
      var $content = $('<div class="tab-pane fade '+active+'" id="nav-'+i+'" role="tabpanel" aria-labelledby="nav-home-tab"></div>');
      $content.html($el);
      $tabsContents.append($content);
      i++;
    });
    $tabNavs.wrap('<nav></nav>')

    $results.html($tabNavs);
    $results.append($tabsContents);

    return $data;
  },

  podium($data){
    var $podium = $data.find('.podium');
    $podium.removeClass('row');
    $podium.find('fieldset > div').each((i, e)=>{
      var $row = $(e);
      $row.addClass('row');

      // process podium item
      var podiumItems = $row.find('> div');
      podiumItems.each((i, item)=>{
        var $item = $(item);

        //flag
        var $country = $item.find('p').eq(1).addClass('country');
        var countryIso = $country.text().replace('(','').replace(')','');
        $item.append('<span class="flag-icon flag-icon-'+countryIso+'"> </span>')



        //podium item
        $item.children('div').first().addClass('podium-header');
        $item.children('div').first().children('div').first().addClass('podium-rank');

        $item.children('div').eq(1).addClass('podium-content');
        $item.children('div').eq(1).children('div').first().addClass('podium-name');
        $item.children('div').eq(1).children('div').eq(1).addClass('podium-time');

        // wrap
        $item.wrapInner('<div class="inner"></div>');
      })

      //move 1 to middle
      $row.prepend(podiumItems.eq(1));
    })

    return $data;
  },

  responsiveTable($data){
    var tables = $data.find('table');
    tables.wrap('<div class="table-responsive"></div>');

    return $data;
  },

  tableResult($data){
    var tables = $data.find('table');
    tables.each((i, e)=>{
      var $th;
      var $tr;

      var $e = $(e);
      if($e.hasClass('table-results')){
        $th = $e.find('th');
        $tr = $e.find('tr');

        $th.first().html('<span class="visible-xs hidden-sm hidden-md hidden-lg">Rk</span><span class="hidden-xs">Rank</span>')
        $th.first().addClass('rank');

        $th.eq(1).addClass('bib');

        if(!$e.hasClass('table-rankings')){
          $th.eq(3).html('Time');
          $th.eq(3).addClass('time');
          $th.eq(5).html('<span class="visible-xs hidden-sm hidden-md hidden-lg">WC Pts</span><span class="hidden-xs">WC Points</span>');
          $th.eq(5).addClass('wc-points');
        }

        $tr.each((i, e)=>{
          var $e = $(e);
          var $td = $e.find('td');
          var r = $td.first().text();
          $td.first().wrapInner('<span></span>');
          $td.first().addClass('rank rank-'+r);

          $td.eq(1).addClass('bib');
          $td.eq(3).addClass('time');
          $td.eq(5).addClass('wc-points');
        });
      } else {
        $e.addClass('other-results');

        $th = $e.find('th');
        $tr = $e.find('tr');
        $th.first().addClass('bib');

        $tr.each((i, e)=>{
          var $e = $(e);
          if(i === 0){
            $e.prepend('<th class="rank"></th>')
          } else {
            $e.prepend('<td class="rank"></td>')
          }

          var $td = $e.find('td');
          $td.eq(1).addClass('bib');
        });
      }

    })


    return $data;
  },

  cleanTable($data){
    var tds = $data.find('table td');
    tds.attr('style',null);
    return $data;
  },

  flagIcon($data){
    var flags = $data.find('.flag-icon');
    flags.each((i, e)=>{
      var $e = $(e);
      var classNames = $e.attr('class');
      var country_ioc = classNames.replace('flag-icon-', '').replace('flag-icon', '').replace(' ', '');
      var country_iso = ioc2iso[country_ioc.toLowerCase()];
      $e.attr('class', 'flag-icon flag-icon-' + country_iso );
    })

    return $data;
  }

}



export default alpineskiingProcessor
